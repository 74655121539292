<template>
  <section class="container">
    <div v-if="!isComplete" class="forget-password"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
      <h3 class="title">パスワードをお忘れの方</h3>

      <div class="forget-password__container step">

        <h4>パスワードをお忘れの方は以下フォームに登録メールアドレスをご入力ください。<br>登録メールアドレスにパスワード変更用URLを送付させていただきます。</h4>

        <ValidationObserver
          slim
          tag="form"
          ref="observer"
          @submit.prevent="validateBeforeSubmit()">

          <div class="step__form ">

            <div class="step__form__group">

              <!--email-->
              <div class="form__control">
                <label class="pc">
                    メールアドレス
                    <span class="required"
                          v-text="'必須'"/>
                  </label>

                <div class="form__control__input">

                <span class="required pc"
                      v-text="'必須'"/>

                  <!--email only pc-->
                  <InputField v-model="email"
                              rules="required|email|max:200"
                              class="w-100 pc"
                              field="メールアドレス"
                              vid="email_pc"/>

                  <!--email only sp-->
                  <template v-if="checkDevice">
                    <InputField  v-model="email"
                                 rules="required|email|max:200"
                                 class="w-100 sp"
                                 field="メールアドレス"
                                 placeholder="メールアドレスを入力"
                                 vid="email_sp"
                                 :prefix="true">

                      <template #prefix>
                        <MailIcon size="22"
                                  class="prefix"/>
                      </template>

                    </InputField>
                  </template>

                  </div>

              </div>

            </div>

          </div>

          <!--action only pc-->
          <button
            class="btn__register"
            type="submit">
            確認する
            <ChevronRightIcon size="24"/>
          </button>
        </ValidationObserver>

      </div>
    </div>

    <div v-else class="forget-password-sent"
         :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
      <h3 class="title">パスワードをお忘れの方</h3>

      <div class="forget-password-sent__container step">

        <h4>以下のメールアドレスにパスワード変更用メールを送信しました。</h4>

        <div class="step__block">

          <!-- ERROR WHEN INPUT TOKEN CODE INCORRECT -->
          <ul v-if="errors && checkWebview()"
              class="error__server">
            <li class="errors-mess">{{ errors }}</li>
          </ul>

          <div :class="{ 'justify-content-start': checkWebview() }"
               class="mail">
            <img src="@/assets/images/icon/icon-mail.svg" alt="icon">
            {{email}}
          </div>

          <!-- FIELD ACCURACY CODE ON MOBILE APP -->
          <ValidationObserver
            v-if="checkWebview()"
            slim
            tag="form"
            ref="observer"
            @submit.prevent="validateBeforeSubmit()">
              <!--code-->
              <div class="form__control mb-3">
                <div class="form__control__input">
                  <InputField  v-model="token_code"
                               rules="required|max:6"
                               type="number"
                               class="w-100 code-token"
                               field="認証コード"
                               placeholder="認証コードを入力"
                               vid="token_code">
                    <template #prefix>
                      <img class="prefix" src="@/assets/images/icon/icon-lock.svg" alt="icon">
                    </template>
                  </InputField>
                </div>
              </div>
          </ValidationObserver>

          <ul class="note01">
            <li>上記のメールアドレス宛に確認メールを送信しました。</li>
            <li>メール内に記載されたURLにアクセスし、必要事項を入力してください。</li>
          </ul>

          <ul class="note02">
            <li>※ドメイン指定受信をされている場合、「chibijob.com」からのメールを受信できるようご設定ください。</li>
            <li>※お使いのメールソフトによっては、メールが「迷惑メール」フォルダに入る場合がございます。メールが届かない場合は、迷惑フォルダもご確認ください。</li>
          </ul>

        </div>

        <!-- BTN CHECK TOKEN IN MOBILE APP -->
        <button
          v-if="checkWebview()"
          class="btn__register mb-3"
          @click.prevent="validateBeforeSubmit">
          次へ
          <ChevronRightIcon size="24"/>
        </button>

        <!-- BTN BACK TO LOGIN -->
        <a class="btn__register"
           @click.prevent="openPageNative('Login')">
          ログイン画面へ
          <ChevronRightIcon size="24"/>
        </a>

      </div>
    </div>

  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Common from '@/mixins/common.mixin'

// Component
import InputField from '@/components/form/InputField'
// Icon
import { ChevronRightIcon, MailIcon } from 'vue-feather-icons'

export default {
  name: 'ForgetPassword',

  mixins: [Common],

  components: {
    InputField,
    ChevronRightIcon,
    MailIcon
  },

  data () {
    return {
      email: '',
      isComplete: false,
      token_code: '',
      errors: null
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  methods: {
    ...mapActions('auth', ['userForgotPassword', 'checkUserToken']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.isComplete ? this.handleSubmitToken() : this.handleSubmit()
      }
    },

    checkDevice () {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return true
      } return false
    },

    handleSubmit () {
      // handle API PORGET PASSWORD
      const params = {
        email: this.email,
        is_mobile_app: this.checkWebview() ? 1 : 0
      }
      this.userForgotPassword(params).then(result => {
        if (result.status === 204) {
          this.isComplete = true
        } else {
          this.$refs.observer.setErrors({
            email_pc: [result.data.error.message],
            email_sp: [result.data.error.message]
          })
        }
      })
    },

    // method check token_code user input is correct?
    handleSubmitToken () {
      const params = {
        token: this.token_code,
        email: this.email,
        is_mobile_app: 1
      }
      this.checkUserToken(params).then(result => {
        if (result.data.success) {
          this.$router.push({
            path: `/reset-password/${result.data.data.token}`,
            query: {
              token: this.token_code,
              email: this.email
            }
          })
        } else {
          this.errors = result.data.error.message
          this.scrollToTop()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-password,.forget-password-sent {
  padding: 90px 0;
  @media #{$info-phone} {
    padding: 85px 0;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 120px 0 85px;
    }
  }
  &.padding-webview {
    padding: 40px 0 0;
  }
  .title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  &__container {
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
  .step {
    padding: 65px;
    @media #{$info-phone} {
      padding: 48px 18px;
    }

    h4 {
      text-align: center;
      font-weight: 500;
      line-height: 1.5;
      @include font-size(18px);
      margin-bottom: 45px;
      @media #{$info-phone} {
        margin-bottom: 36px;
        text-align: left;
        font-size: 18px;
      }
    }

    &__form {
      background-color: #E7E5DE;
      border-radius: 6px;
      margin-bottom: 60px;
      @media #{$info-phone} {
        padding: 24px 18px;
        margin-bottom: 20px;
      }

      &__group {
        padding: 24px;
        @media #{$info-phone} {
          padding: 0;
        }
        .form__control {
          display: flex;
          align-items: flex-start;
          @media #{$info-phone} {
            display: block;
          }
          label {
            width: 235px;
            flex-shrink: 0;
            @include font-size(18px);
            margin-top: 13px;
            @media #{$info-phone} {
              width: 100%;
              font-size: 16px;
              display: block;
              margin-bottom: 12px;
            }
            .required {
              display: none;
              color: #C66C44;
              @include font-size(14px);
              font-family: $font-family-title-B;
              margin-top: 16px;
              margin-left: 8px;
              @media #{$info-phone} {
                display: inline-block;
              }
            }
          }
        }

        .form__control__input {
          display: flex;
          align-items: flex-start;
          flex: 1;
          .required {
            color: #C66C44;
            @include font-size(14px);
            font-weight: bold;
            flex-shrink: 0;
            margin-right: 24px;
            margin-top: 16px;
            @media #{$info-phone} {
              display: none;
            }
          }
        }

      }

      .btn__register.sp-btn {
        display: none;
        @media #{$info-phone} {
          display: flex;
        }
      }

    }

    .btn__register {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 360px;
      width: 100%;
      border-radius: 6px;
      color: #ffffff;
      background: #6F8784;
      font-family: $font-family-title;
      @include font-size(18px);
      padding: 14px;
      margin: auto;
      cursor: pointer;

      @media #{$info-phone} {
        font-size: 16px;
        padding: 10px;
        max-width: 100%;
      }

      svg {
        margin-left: 5px;
      }
    }

  }
}

.forget-password-sent {
  .step {

    &__block {
      background-color: #E7E5DE;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 22px;
      padding: 36px 108px;
      margin-bottom: 47px;

      @media #{$info-tablet-vertical} {
        padding: 36px 70px;
      }

      @media #{$info-phone} {
        padding: 24px 18px;
        margin-bottom: 20px;
      }

      .mail {
        background-color: #ffffff;
        text-align: center;
        border-radius: 6px;
        margin-bottom: 25px;
        font-family: $font-family-title;
        color: $default-color;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media #{$info-phone} {
          font-size: 16px;
          padding: 10px 15px;
        }

        img {
          margin-right: 12px;
          width: 20px;
        }
      }

      ul {
        li {
          line-height: 28px;
          font-weight: 500;
          @include font-size(16px);
          @media #{$info-phone} {
            font-size: 16px;
          }
          &.errors-mess {
            font-size: unset;
            font-weight: 600;
          }
        }
      }

      .note01 {
        border-bottom: 1px solid #ffffff;
        padding-bottom: 28px;
        margin-bottom: 28px;
        @media #{$info-phone} {
          padding-bottom: 24px;
          margin-bottom: 24px;
        }
      }

      .note02 {
        li {
          text-indent: -10px;
          margin-left: 10px;
        }
      }
    }
  }
}
@media #{$info-phone} {
  .container {
    padding: 0;
  }
}
</style>
